import PreCreditBank from '../components/pre-credit-bank.vue'
import enterprise from '../components/enterprise.vue'
import { formatDate } from '@/utils/auth/common'
export const paymentForm = (content) => {
  const cols = { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
  return [
    {
      label: '预授信银行',
      prop: 'capitalSideCode',
      attrs: {
        placeholder: '请输入',
        disabled: true, // content.applyType === 'V',
        options: content.capitalList,
        selectedField: ['keyId', 'capitalSideName'],
        clearable: true
      },
      span: 24,
      tag: PreCreditBank,
      on: {
        change: (data, row) => {
          content.getCompany(row)
        }
      },
      rules: [
        { required: true, message: '预授信银行不能为空', trigger: ['change', 'blur'] }
      ]
    },
    {
      label: '企业名称',
      prop: 'gysId',
      cols,
      attrs: {
        placeholder: '请输入',
        disabled: content.applyType === 'V',
        count: content.companyIndex,
        capitalSideCode: content.companyData.capitalSideCode,
        supplierData: content.supplierData,
        companyData: content.companyData,
        options: content.dropOptions,
        selectedField: ['keyId', 'enterpriseName'],
        filterable: true
      },
      tag: enterprise,
      on: {
        change: (data, row, index) => {
          content.$set(content, 'supplierData', row)
          content.getSupplierInfo(row)
        },
        syncSuccess (data) {
          content.syncSuccess(data)
        }
      },
      rules: [
        { required: true, message: '企业名称不能为空', trigger: ['change', 'blur'] }
      ]
    },
    {
      label: '上次同步时间',
      prop: 'syncTime',
      cols,
      attrs: {
        placeholder: '请输入',
        disabled: true
      },
      rules: [
        { required: true, message: '上次同步时间不能为空', trigger: ['change', 'blur'] }
      ]
    },
    {
      label: '统一社会信用代码',
      prop: 'creditCode',
      cols,
      attrs: {
        placeholder: '请输入',
        disabled: true
      },
      rules: [
        { required: true, message: '统一社会信用代码不能为空', trigger: ['change', 'blur'] }
      ]
    },
    {
      label: '法定代表人证件类型',
      prop: 'typeOfCertificate',
      cols,
      attrs: {
        placeholder: '请输入',
        disabled: true
      },
      rules: [
        { required: true, message: '法定代表人证件类型不能为空', trigger: ['change', 'blur'] }
      ]
    },
    {
      label: '法定代表人姓名',
      prop: 'representativeName',
      cols,
      attrs: {
        placeholder: '请输入',
        disabled: true
      },
      rules: [
        { required: true, message: '法定代表人姓名不能为空', trigger: ['change', 'blur'] }
      ]
    },
    {
      label: '联系人',
      prop: 'contact',
      cols,
      attrs: {
        placeholder: '请输入',
        disabled: true
      },
      rules: [
        { required: true, message: '联系人不能为空', trigger: ['change', 'blur'] }
      ]
    },
    {
      label: '联系人手机号码',
      prop: 'contactPhone',
      cols,
      attrs: {
        placeholder: '请输入',
        disabled: true
      },
      rules: [
        { required: true, message: '联系人手机号码不能为空', trigger: ['change', 'blur'] }
      ]
    },
    {
      label: '合作年限（年）',
      prop: 'cooperationPeriod',
      cols,
      attrs: {
        placeholder: '请输入',
        disabled: true
      },
      rules: [
        { required: true, message: '合作年限不能为空', trigger: ['change', 'blur'] }
      ]
    },
    {
      label: '供应商评级等级',
      prop: 'supplierRating',
      cols,
      attrs: {
        placeholder: '请输入',
        disabled: true
      },
      rules: [
        { required: true, message: '供应商评级等级不能为空', trigger: ['change', 'blur'] }
      ]
    },
    {
      label: '近两年合同金额（元）',
      prop: 'contractAmount',
      cols,
      attrs: {
        placeholder: '请输入',
        disabled: true
      },
      rules: [
        { required: true, message: '近两年合同金额不能为空', trigger: ['change', 'blur'] }
      ]
    },
    {
      label: '企业所在省名称',
      prop: 'province',
      cols,
      attrs: {
        placeholder: '请输入',
        disabled: true
      },
      rules: [
        { required: true, message: '企业所在省名称不能为空', trigger: ['change', 'blur'] }
      ]
    },
    {
      label: '企业所在市名称',
      prop: 'city',
      cols,
      attrs: {
        placeholder: '请输入',
        disabled: true
      },
      rules: [
        { required: true, message: '企业所在市名称不能为空', trigger: ['change', 'blur'] }
      ]
    },
    {
      label: '注册地',
      prop: 'registration',
      cols: { lg: 10 },
      attrs: {
        placeholder: '请输入',
        disabled: true
      },
      rules: [
        { required: true, message: '注册地不能为空', trigger: ['change', 'blur'] }
      ]
    }
  ]
}
// 付款单表格
export const paymentTable = (content) => {
  return [
    {
      label: '序号',
      prop: 'index',
      width: '60px'
    },
    {
      label: '年月',
      prop: 'yearAndMonth',
      minWidth: '150px',
      formatter: row => {
        return row.yearAndMonth ? formatDate(row.yearAndMonth, 'YY-MM-DD').slice(0, 7) : ''
      }
    },
    {
      label: '产值(元)',
      prop: 'paymentApplyAmount',
      align: 'right',
      formatter: row => {
        return (row.paymentApplyAmount ? row.paymentApplyAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00')
      }
    },
    {
      label: '应付金额(元)',
      prop: 'settmentAmount',
      minWidth: '100px',
      align: 'right',
      formatter: row => {
        return (row.settmentAmount ? row.settmentAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00')
      }
    },
    {
      label: '已付金额(元)',
      prop: 'paidAmount',
      minWidth: '100px',
      align: 'right',
      formatter: row => {
        return (row.paidAmount ? row.paidAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00')
      }
    }
  ]
}
