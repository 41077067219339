<template>
<el-row :gutter="24">
  <el-col :xs="24" :sm= '12' :md='8' :lg='5' :xl='6'>
    <base-select v-bind="$attrs" v-on="$listeners"></base-select>
  </el-col>
</el-row>
</template>
<script>
import BaseSelect from '@/components/common/base-select/base-select.vue'
export default {
  data () {
    return {}
  },
  components: { BaseSelect },
  methods: {
    // change (data) {
    //   this.$emit('change', data)
    // }
  }
}
</script>
