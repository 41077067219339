<template>
<!-- 申请授信 -->
<div class="applyForRufs">
  <backtitle></backtitle>
  <div class="addProcess">
    <base-form
      :componentList="addForm"
      :formAttrs="{
        model: companyData,
        labelWidth: '140px',
      }"
      class="formStyle"
      ref="addFormData"
      :showBtns="false"
    >
    </base-form>
    <div>
      <p>近24月产值及已付金额情况：
        <span class="padding_right">近24月产值合计{{sumData.paymentApplyAmountSum.toLocaleString('en-US', { minimumFractionDigits: 2 })}}元</span>
        <span class="padding_right">应付金额合计{{sumData.settmentAmountSum.toLocaleString('en-US', { minimumFractionDigits: 2 })}}元</span>
        <span>已付金额合计{{sumData.paidAmountSum.toLocaleString('en-US', { minimumFractionDigits: 2 })}}元</span>
      </p>
    </div>
    <!-- 表格 -->
    <base-table
        :columns="paymentColumns"
        :showPage="false"
        :tableAttrs="{
          data: tableData,
          stripe: true,
        }"
        :queryParas="queryParas"
        :webPage="true"
        ref="tableData"
        :selfChangeHeight="105"
        @pageIndexChange="pageIndexChange"
        @pageSizeChange="pageSizeChange"
      >
        <template slot="index" slot-scope="scope">
          {{scope.$index + 1}}
        </template>
    </base-table>
  </div>
  <div class="footer">
    <base-button label="申请预授信" @click="submit" v-if="applyType == 'A'"></base-button>
    <base-button
      label="关 闭"
      type="default"
      @click="close"
    ></base-button>
  </div>
</div>
</template>
<script>

import Backtitle from '../components/backtitle.vue'
import baseForm from '@/components/common/base-form/base-form.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import { paymentForm, paymentTable } from './utils/apply-for-config'
import { psbcApi } from '@/api/psbcApi'
import storage from '@/utils/storage'
import { supplierApi } from '@/api/companyApi'
export default {
  components: { Backtitle, baseForm, BaseButton, BaseTable },
  props: {
    capitalSideCode: String
  },
  data () {
    return {
      capitalList: [],
      companyData: {},
      loadCount: 0,
      tableData: [],
      changeData: [],
      applyType: '', // V查看，A申请
      companyIndex: 0,
      queryParas: {
        pageIndex: 1,
        pageSize: 10000
      },
      sumData: {
        paymentApplyAmountSum: 0,
        settmentAmountSum: 0,
        paidAmountSum: 0
      },
      preCreditInfo: {},
      supplierData: {},
      dropOptions: []
    }
  },
  computed: {
    addForm () {
      return paymentForm(this)
    },
    paymentColumns () {
      return paymentTable(this)
    },
    api () {
      return psbcApi
    }
  },
  created () {
    this.$nextTick(() => {
      this.$refs.addFormData.clearValidate()
    })
    this.applyType = this.$route.query.type
    this.preCreditInfo = storage.getSession('preCreditInfo')
    this.getCapitalList()
    if (this.applyType === 'V') {
      // this.$set(this.companyData, 'capitalSideCode', this.preCreditInfo.capitalSideCode)
      // this.$set(this.companyData, 'gysId', this.preCreditInfo.gysId)
      // this.getCompany(this.preCreditInfo)
      // this.getSupplierInfo(this.preCreditInfo)
      this.getPreCreditDetails()
    }
  },
  mounted () {
    this.gysList()
  },
  methods: {
    // 获取供应商数据
    gysList () {
      supplierApi.getList().then((res) => {
        if (res.success) {
          this.dropOptions = res.data
        }
      })
    },
    // 获取预授信详情
    getPreCreditDetails () {
      const params = {
        keyId: this.preCreditInfo.keyId,
        ...this.queryParas
      }
      this.api.getPreCreditDetails(params).then(res => {
        if (res.data) {
          this.companyData = res.data
          this.getCompany(this.companyData)
          this.sumData = {
            paymentApplyAmountSum: res.data.paymentApplyAmountSum,
            settmentAmountSum: res.data.settmentAmountSum,
            paidAmountSum: res.data.paidAmountSum
          }
          if (res.data.authorization) {
            this.tableData = res.data.authorization.records
          }
        }
      })
    },
    close () {
      this.$router.back()
    },
    // 同步供应商信息成功后
    syncSuccess (data) {
      this.getSupplierInfo(data)
    },
    // 清除数据
    clearData (code, name) {
      this.companyData = {
        capitalSideCode: code,
        capitalSideName: name
      }
      this.tableData = []
      this.sumData = {
        paymentApplyAmountSum: 0,
        settmentAmountSum: 0,
        paidAmountSum: 0
      }
      this.$nextTick(() => {
        this.$refs.addFormData.clearValidate()
      })
    },
    // 获取授信银行数据
    getCapitalList () {
      this.api.getApplicableCapitalList().then(res => {
        this.capitalList = res.data
        if (this.capitalList.length && this.applyType === 'A') {
          this.capitalList.forEach(ele => {
            if (ele.capitalSideName === '邮储银行') {
              this.companyData.capitalSideCode = ele.keyId
              this.getCompany(ele)
            }
          })
        }
      })
    },
    // 获取授信银行数据触发获取企业数据
    getCompany (data) {
      this.$set(this.companyData, 'capitalSideName', data.capitalSideName)
      this.companyIndex++
      if (!data || JSON.stringify(data) === '{}') {
        this.clearData()
      } else if (this.applyType === 'A') {
        this.clearData(this.companyData.capitalSideCode, this.companyData.capitalSideName)
      }
    },
    // 获取供应商基本信息
    getSupplierInfo (data) {
      if (data && JSON.stringify(data) !== '{}') {
        const params = {
          supplierName: data.enterpriseName,
          creditCode: data.creditCode
        }
        this.api.getSupplierInfo(params).then(res => {
          this.companyData = {
            ...res.data,
            capitalSideCode: this.companyData.capitalSideCode, // 授信银行名称
            capitalSideName: this.companyData.capitalSideName, // 授信银行id
            gysId: this.companyData.gysId, // 供应商id
            contractAmount: res.data.contractAmount ? res.data.contractAmount : '0.00', // 近两年合同金额
            companyName: data.enterpriseName // 供应商名称
          }
          this.$nextTick(() => {
            this.$refs.addFormData.clearValidate()
          })
        })
        this.$nextTick(() => {
          this.getOutputValue(data)
        })
        this.getSum(data)
      } else {
        this.clearData(this.companyData.capitalSideCode, this.companyData.capitalSideName)
      }
    },
    // 获取24个月产值数据
    getOutputValue (data) {
      if (data) {
        this.queryParas = {
          ...this.queryParas,
          supplierName: data.enterpriseName,
          creditCode: data.creditCode
        }
      }

      this.api.getOutputValue(this.queryParas).then(res => {
        this.tableData = res.data
      })
    },
    pageIndexChange (item) {
      this.queryParas.pageIndex = item
      if (this.applyType === 'V') {
        this.getPreCreditDetails()
      } else {
        this.getOutputValue()
      }
    },
    pageSizeChange (item) {
      this.queryParas.pageSize = item
      this.queryParas.pageIndex = 1
      if (this.applyType === 'V') {
        this.getPreCreditDetails()
      } else {
        this.getOutputValue()
      }
    },
    // 获取产值合计数据
    getSum (data) {
      const params = {
        supplierName: data.enterpriseName,
        creditCode: data.creditCode
      }
      this.api.getSum(params).then(res => {
        if (res.data && JSON.stringify(res.data) !== '{}') {
          this.sumData = res.data
        } else {
          this.sumData = {
            paymentApplyAmountSum: 0,
            settmentAmountSum: 0,
            paidAmountSum: 0
          }
        }
      })
    },
    // 提交新增修改
    async submit () {
      await this.$refs.addFormData.validate()
      if (this.companyData.capitalSideCode && this.companyData.gysId) {
        if (!this.companyData.contact) {
          return this.warning('联系人不存在，请前往供应商管理页面添加联系人信息。')
        }
        if (this.tableData.length === 0) {
          return this.warning('近24月产值数据不存在，不能进行预授权申请')
        }
        const authorization = this.tableData.map(item => {
          return {
            outputValue: item.paymentApplyAmount,
            paiAmount: item.paidAmount,
            payableAmounts: item.settmentAmount,
            yearAndMoon: item.yearAndMonth
          }
        })
        const params = {
          ...this.companyData,
          ageLimit: this.companyData.cooperationPeriod,
          certificateCode: this.companyData.creditCode,
          cityName: this.companyData.city,
          contactPersoNum: this.companyData.contactPhone,
          contactPerson: this.companyData.contact,
          legalPersonCaType: this.companyData.typeOfCertificate,
          legalPersonName: this.companyData.representativeName,
          provinceName: this.companyData.province,
          rating: this.companyData.supplierRating,
          regAddr: this.companyData.registration,
          paymentApplyAmountSum: this.sumData.paymentApplyAmountSum,
          settmentAmountSum: this.sumData.settmentAmountSum,
          paidAmountSum: this.sumData.paidAmountSum,
          authorization: authorization
        }
        this.api.saveAuthorization(params).then(res => {
          this.success('申请成功')
          this.$router.back()
        })
      } else {
        this.warning('请完善数据')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.padding_right {
  padding-right: 20px;
}
.addProcess {
  background: #fff;
  height: calc(100% - 115px);
  overflow-y: auto;
  padding-bottom: 20px;
  padding: 0px 10px 20px;
}
// 底部样式
.footer {
  text-align: center;
  margin: 0px 0 10px;
  padding: 14px 0;
  box-shadow: 0px 0px 13px 2px rgba(#4A7CF0, 0.2);
  background: #ffffff;
  position: relative;
}
</style>
